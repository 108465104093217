import React from 'react'

const GeoReference = () => {
  return (
    <>
      <div className='reference-title'>Reference</div>
      <div className='reference-box'>
        <div className='reference-card'>
          <div className='reference-picture reference-picture-1'>
            <div className='reference-heading'>2023</div>
          </div>
          <div className='reference-detail'>
            <ul>
              <li>70 realizací</li>
              <li>300 000 m²</li>
              <li>50 odběratelů</li>
              <li>1 nový stroj</li>
              <li>
                <button className='reference-detail-button'>
                  Zobrazit detaily
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='reference-card'>
          <div className='reference-picture reference-picture-2'>
            <div className='reference-heading'>2022</div>
          </div>
          <div className='reference-detail'>
            <ul>
              <li>80 realizací</li>
              <li>530 000 m²</li>
              <li>60 odběratelů</li>
              <li>2 nové stroje</li>
              <li>
                <button className='reference-detail-button'>
                  Zobrazit detaily
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='reference-card'>
          <div className='reference-picture reference-picture-3'>
            <div className='reference-heading'>2021</div>
          </div>
          <div className='reference-detail'>
            <ul>
              <li>47 realizací</li>
              <li>320 000 m²</li>
              <li>40 odběratelů</li>
              <li>2 nové stroje</li>
              <li>
                <button className='reference-detail-button'>
                  Zobrazit detaily
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='reference-card'>
          <div className='reference-picture reference-picture-4'>
            <div className='reference-heading'>2020</div>
          </div>
          <div className='reference-detail'>
            <ul>
              <li>32 realizací</li>
              <li>200 000 m²</li>
              <li>36 odběratelů</li>
              <li>1 nový stroj</li>
              <li>
                <button className='reference-detail-button'>
                  Zobrazit detaily
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeoReference
