import React from 'react'
import Modal from 'react-modal'

import { HiX } from 'react-icons/hi'

const Servis = ({ isOpen, closeServis }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeServis}
      className='modal'
      contentLabel='Bulk Edit'
      overlayClassName='modal-overlay'
    >
      <button className='modal-close' onClick={closeServis}>
        <HiX className='modal-close-icon' />
      </button>
    </Modal>
  )
}

export default Servis
