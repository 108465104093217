import React from 'react'
import Modal from 'react-modal'

import { HiX } from 'react-icons/hi'

const Recyclation = ({ isOpen, closeRecyclation }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeRecyclation}
      className='modal modal-recyclation'
      contentLabel='Bulk Edit'
      overlayClassName='modal-overlay'
    >
      <button className='modal-close' onClick={closeRecyclation}>
        <HiX className='modal-close-icon' />
      </button>
    </Modal>
  )
}

export default Recyclation
