import React from 'react'

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

const GeoJobs = () => {
  return (
    <>
      <div className='jobs-content'>
        <div className='jobs-title'>Kariéra</div>
        <div className='jobs-subtitle'>Aktuálně hledáme kolegy na pozice</div>
        <div className='jobs-sides'>
          <div className='jobs-block-left'>
            <div className='jobs-item'>
              <div className='jobs-item-title-box'>
                <div className='jobs-item-title'>
                  Strojník stavebních strojů
                </div>
                <div className='jobs-item-subtitle'>
                  <FaMapMarkerAlt className='jobs-item-subtitle-icon' />
                  <div className='jobs-item-subtitle-address'>
                    Nové Město nad Metují
                  </div>
                </div>
              </div>
              <div className='jobs-item-detail-box'>
                <div className='jobs-item-detail-type'>Plný úvazek</div>
                <button className='jobs-item-detail-button'>
                  Zobrazit detail
                </button>
              </div>
            </div>
            <div className='jobs-item'>
              <div className='jobs-item-title-box'>
                <div className='jobs-item-title'>
                  Řidič nákladního automobilu
                </div>
                <div className='jobs-item-subtitle'>
                  <FaMapMarkerAlt className='jobs-item-subtitle-icon' />
                  <div className='jobs-item-subtitle-address'>
                    Nové Město nad Metují
                  </div>
                </div>
              </div>
              <div className='jobs-item-detail-box'>
                <div className='jobs-item-detail-type'>Plný úvazek</div>
                <button className='jobs-item-detail-button'>
                  Zobrazit detail
                </button>
              </div>
            </div>
            <div className='jobs-item'>
              <div className='jobs-item-title-box'>
                <div className='jobs-item-title'>Stavební technik</div>
                <div className='jobs-item-subtitle'>
                  <FaMapMarkerAlt className='jobs-item-subtitle-icon' />
                  <div className='jobs-item-subtitle-address'>
                    Nové Město nad Metují
                  </div>
                </div>
              </div>
              <div className='jobs-item-detail-box'>
                <div className='jobs-item-detail-type'>Plný úvazek</div>
                <button className='jobs-item-detail-button'>
                  Zobrazit detail
                </button>
              </div>
            </div>
          </div>
          <div className='jobs-block-right'>
            <div className='jobs-block-right-text'>
              Nenašli jste, co jste hledali? Zkuste se podívat na nabídku našich
              sesterských společností nebo kontaktujte naši personalistku.
            </div>
            <div className='jobs-block-right-contact'>
              <div className='jobs-block-right-initials'>
                <div className='jobs-block-right-name'>Bc. Jitka Kubečková</div>
                <div className='jobs-block-right-position'>personalistika</div>
                <div className='jobs-block-right-icon-block'>
                  <FaPhoneAlt className='jobs-block-right-icon' />
                  <div className='jobs-block-right-icon-text'>
                    +420 607 913 024
                  </div>
                </div>

                <div className='jobs-block-right-icon-block'>
                  <FaEnvelope className='jobs-block-right-icon' />
                  <div className='jobs-block-right-icon-text'>
                    kubeckovaj@zba.cz
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeoJobs
