import React from 'react'

import {
  FaSquarePhone,
  FaSquareEnvelope,
  FaSquareFacebook,
  FaSquareInstagram,
  FaLinkedin,
  FaAnglesDown,
} from 'react-icons/fa6'

const GeoHeader = ({ scrollToAbout }) => {
  return (
    <>
      <div className='header__socials'>
        <a href='https://www.example.io/facebook' target='blank'>
          <FaSquareFacebook className='header__socials__icon' />
        </a>
        <a href='https://www.example.io/instagram' target='blank'>
          <FaSquareInstagram className='header__socials__icon' />
        </a>
        <a href='https://www.example.io/linkedin' target='blank'>
          <FaLinkedin className='header__socials__icon' />
        </a>
        <a href='mailto:zba@zba.cz'>
          <FaSquareEnvelope className='header__socials__icon' />
        </a>
        <a href='tel:+420111111111'>
          <FaSquarePhone className='header__socials__icon' />
        </a>
      </div>
      <div className='header-title-box'>
        <div className='header-title'>Na naší práci stojí vaše stavby</div>
        <div className='header-separator'></div>
        <div className='header-subtitle'>
          Již 30 let jednička v oboru úprav zemin
        </div>
      </div>
    </>
  )
}

export default GeoHeader
