import React from 'react'
import GeoNav from '../layout/GeoNav'
import GeoAbout from '../layout/GeoAbout'
import GeoReference from '../layout/GeoReference'
import GeoHeader from '../layout/GeoHeader'
import GeoJobs from '../layout/GeoJobs'
import GeoContact from '../layout/GeoContact'
import { animateScroll as scroll } from 'react-scroll'

import iso9001 from '../resources/imgs/iso9001.png'
import sjpk from '../resources/imgs/sjpk.png'
import iso14001 from '../resources/imgs/iso14001.png'
import iso45001 from '../resources/imgs/iso45001.png'

const GeoTech = () => {
  const currentYear = new Date().getFullYear()

  const scrollToSection = (sectionId, offset) => {
    scroll.scrollTo(document.getElementById(sectionId).offsetTop + offset, {
      smooth: true,
    })
  }

  const scrollToHeader = () => {
    scrollToSection('header-section', 0)
  }

  const scrollToAbout = () => {
    scrollToSection('about-section', -40)
  }

  const scrollToReference = () => {
    scrollToSection('reference-section', -40)
  }

  const scrollToJobs = () => {
    scrollToSection('jobs-section', -80)
  }

  const scrollToContact = () => {
    scrollToSection('contact-section', -80)
  }

  return (
    <>
      <header className='header' id='header-section'>
        <GeoNav
          scrollToAbout={scrollToAbout}
          scrollToHeader={scrollToHeader}
          scrollToReference={scrollToReference}
          scrollToJobs={scrollToJobs}
          scrollToContact={scrollToContact}
        />
        <GeoHeader scrollToAbout={scrollToAbout} />
      </header>

      <section className='about' id='about-section'>
        <GeoAbout />
      </section>

      <section className='reference' id='reference-section'>
        <GeoReference />
      </section>

      <section className='jobs' id='jobs-section'>
        <GeoJobs />
      </section>

      <section className='contact' id='contact-section'>
        <GeoContact />
      </section>

      <footer className='footer'>
        <div className='footer-certificates'>
          <a href='www.zba.cz' target='blank'>
            <img src={iso9001} alt='ISO 9001' className='footer-certificate' />
          </a>
          <a href='www.zba.cz' target='blank'>
            <img
              src={sjpk}
              alt='ISO 9001 - SJ-PK'
              className='footer-certificate'
            />
          </a>
          <a href='www.zba.cz' target='blank'>
            <img
              src={iso14001}
              alt='ISO 14001'
              className='footer-certificate'
            />
          </a>
          <a href='www.zba.cz' target='blank'>
            <img
              src={iso45001}
              alt='ISO 45001'
              className='footer-certificate'
            />
          </a>
        </div>
        <div className='footer-copyright'>
          © {currentYear} ZBA - Všechna práva vyhrazena
        </div>
      </footer>
    </>
  )
}

export default GeoTech
