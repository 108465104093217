import React from 'react'
import Modal from 'react-modal'

import { HiX } from 'react-icons/hi'

const Lab = ({ isOpen, closeLab }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeLab}
      className='modal'
      contentLabel='Bulk Edit'
      overlayClassName='modal-overlay'
    >
      <button className='modal-close' onClick={closeLab}>
        <HiX className='modal-close-icon' />
      </button>
    </Modal>
  )
}

export default Lab
