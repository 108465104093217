import React, { useState } from 'react'
import Stabilization from '../components/Stabilization'
import Recyclation from '../components/Recyclation'
import Servis from '../components/Servis'
import Lab from '../components/Lab'

// IMAGES IMPORT
import stabilizace from '../resources/imgs/stabilizace.png'
import recyklace from '../resources/imgs/recyklace.png'
import servis from '../resources/imgs/servis.png'
import laborator from '../resources/imgs/laborator.png'

const GeoAbout = () => {
  const [stabilOpen, setStabilOpen] = useState(false)
  const [recycleOpen, setRecycleOpen] = useState(false)
  const [servisOpen, setServisOpen] = useState(false)
  const [labOpen, setLabOpen] = useState(false)

  // OPEN/CLOSE STABILIZATION MODAL
  const openStabilization = () => setStabilOpen(true)
  const closeStabilization = () => {
    setStabilOpen(false)
  }

  // OPEN/CLOSE RECYCLATION MODAL
  const openRecyclation = () => setRecycleOpen(true)
  const closeRecyclation = () => {
    setRecycleOpen(false)
  }

  // OPEN/CLOSE SERVIS MODAL
  const openServis = () => setServisOpen(true)
  const closeServis = () => {
    setServisOpen(false)
  }

  // OPEN/CLOSE LAB MODAL
  const openLab = () => setLabOpen(true)
  const closeLab = () => {
    setLabOpen(false)
  }

  return (
    <>
      <div className='about-left'>
        <div className='about-left-content-block'>
          <div className='about-left-title'>Co děláme?</div>
          <div className='about-left-subtitle'>
            Naším cílem je stavební realizace v krátkém čase, vysoké kvalitě a
            rozumné cenové úrovni.
          </div>
          <div className='about-left-paragraph'>
            Jsme významnou, ryze <b>českou stavební společností,</b> která
            působí na trhu <b>od roku 1992.</b> Od svého založení se
            specializujeme na <b>provádění úprav zemin.</b> Disponujeme
            kapacitou vlastních <b>kvalifikovaných zaměstnanců</b> a špičkové
            techniky. V zájmu zvyšování kvality, posílení ochrany životního
            prostředí a zvýšení bezpečnosti práce jsme <b>plně certifikování</b>{' '}
            podle norem{' '}
            <b>ISO 9001 včetně nadstavby SJ-PK, ISO 14001 a ISO 45001.</b>{' '}
            Nedílnou součástí naší společnosti je středisko laboratoří, díky
            kterému jsme schopni{' '}
            <b>navrhnout technologii úprav zemin na míru</b> každé realizované
            stavbě.
          </div>
        </div>
      </div>
      <div className='about-right'>
        <div className='about-right-content-block'>
          <div className='about-right-spec-card'>
            <img
              src={stabilizace}
              alt='Úpravy zemin'
              className='about-right-card-icon'
            />
            <div className='about-right-card-title'>Úpravy zemin</div>
            <div className='about-right-card-paragraph'>
              Zlepšování a stabilizace zemin na místě je ekonomická a k
              životnímu prostředí přívětivá technologie.
            </div>
            <button
              className='about-right-card-button'
              onClick={openStabilization}
            >
              Zobrazit detail »
            </button>
          </div>
          <div className='about-right-spec-card'>
            <img
              src={recyklace}
              alt='Recyklace vozovek za studena'
              className='about-right-card-icon'
            />
            <div className='about-right-card-title'>Recyklace za studena</div>
            <div className='about-right-card-paragraph'>
              Moderní, efektivní a k životnímu prostředí šetrná technologie
              založena na druhotném zpracování konstrukčních vrstev.
            </div>
            <button
              className='about-right-card-button'
              onClick={openRecyclation}
            >
              Zobrazit detail »
            </button>
          </div>
          <div className='about-right-spec-card'>
            <img
              src={laborator}
              alt='Laboratoř mechaniky zemin'
              className='about-right-card-icon'
            />
            <div className='about-right-card-title'>
              Laboratoř mechaniky zemin
            </div>
            <div className='about-right-card-paragraph'>
              Středisko laboratoří se zaměřuje na navrhování řešení neúnosných
              poměrů podloží a další geotechnické aspekty staveb.
            </div>
            <button className='about-right-card-button' onClick={openLab}>
              Zobrazit detail »
            </button>
          </div>
          <div className='about-right-spec-card'>
            <img
              src={servis}
              alt='Servis strojů'
              className='about-right-card-icon'
            />
            <div className='about-right-card-title'>
              Servis stavebních strojů
            </div>
            <div className='about-right-card-paragraph'>
              Ve svých servisních dílnách nabízíme možnost smluvních oprav
              stavebních, zemědělských a lesnických strojů.
            </div>
            <button className='about-right-card-button' onClick={openServis}>
              Zobrazit detail »
            </button>
          </div>
        </div>
      </div>
      <Stabilization
        closeStabilization={closeStabilization}
        isOpen={stabilOpen}
      />
      <Recyclation closeRecyclation={closeRecyclation} isOpen={recycleOpen} />
      <Servis closeServis={closeServis} isOpen={servisOpen} />
      <Lab closeLab={closeLab} isOpen={labOpen} />
    </>
  )
}

export default GeoAbout
