import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainPage from './pages/MainPage'
import GeoTech from './pages/GeoTech'
import Ferokov from './pages/Ferokov'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/geotech' element={<GeoTech />} />
        <Route path='/ferokov' element={<Ferokov />} />
        <Route path='*' element={<MainPage />} />
      </Routes>
    </Router>
  )
}

export default App
