import React from 'react'

import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

const ContactCard = ({ contact }) => {
  return (
    <div className='contact-item'>
      <div className='contact-item-contact'>
        <div className='contact-item-initials'>
          <div className='contact-item-name'>{contact.name}</div>
          <div className='contact-item-position'>{contact.position}</div>
          <div className='contact-item-icon-block'>
            <FaPhoneAlt className='contact-item-icon' />
            <div className='contact-item-icon-text'>{contact.phone}</div>
          </div>

          <div className='contact-item-icon-block'>
            <FaEnvelope className='contact-item-icon' />
            <div className='contact-item-icon-text'>{contact.email}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
