import React from 'react'
import ContactCard from '../components/ContactCard'
import { FaMapMarkerAlt } from 'react-icons/fa'

import geoContacts from '../resources/geotech/geoContacts.json'

const GeoContact = () => {
  return (
    <>
      <div className='contact-title'>Kontakty</div>
      <div className='contact-subtitle'>Stavební a technický úsek</div>
      <div className='contact-box'>
        <div className='contact-content'>
          {geoContacts.map(contact => (
            <ContactCard contact={contact} />
          ))}
        </div>
        <div className='contact-form-container'>
          <div className='contact-form-subtitle'>
            Máte projekt, ve kterém byste chtěli využít našich služeb? Neváhejte
            nás kontaktovat.
          </div>
          <div className='contact-form-box'>
            <div className='contact-form-box-title'>Klientská konzultace</div>
            <div className='contact-form-box-text contact-form-box-text-1'>
              zba@zba.cz
            </div>
            <div className='contact-form-box-text contact-form-box-text-2'>
              +420 602 445 960
            </div>
          </div>
          <FaMapMarkerAlt className='contact-form-icon' />
          <div className='contact-form-address-box'>
            <div className='contact-form-address-box-left'>
              <div className='contact-form-address-box-title'>Sídlo</div>
              <div className='contact-form-address-box-line'>
                ZBA GeoTech s.r.o.
              </div>
              <div className='contact-form-address-box-line'>
                Nademlejnská 651/7
              </div>
              <div className='contact-form-address-box-line'>
                198 00 Praha 9 - Hloubětín
              </div>
              <br />
              <div className='contact-form-address-box-line'>
                <b>IČ:</b> 25296787
              </div>
              <div className='contact-form-address-box-line'>
                <b>DIČ:</b> CZ699000232
              </div>
              <br />
              <a
                href='https://maps.app.goo.gl/PNiNU9igtPHLLL8N9'
                target='blank'
                className='contact-form-address-box-link'
              >
                Zobrazit mapu
              </a>
            </div>
            <div className='contact-form-address-box-right'>
              <div className='contact-form-address-box-title'>Provozovna</div>
              <div className='contact-form-address-box-line'>
                ZBA GeoTech s.r.o.
              </div>
              <div className='contact-form-address-box-line'>Slavoňov 82</div>
              <div className='contact-form-address-box-line'>
                549 01 Nové Město nad Metují
              </div>
              <br />
              <div className='contact-form-address-box-line'>
                <b>Provozní doba:</b> Po-Pá 7:00 - 15:00
              </div>
              <div className='contact-form-address-box-line'>
                <b>Datová schránka:</b> pxccq3b
              </div>
              <br />
              <a
                href='https://maps.app.goo.gl/Ne8QQHd3hs4r66aL8'
                target='blank'
                className='contact-form-address-box-link'
              >
                Zobrazit mapu
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GeoContact
