import React from 'react'
import { Link } from 'react-router-dom'

import geotech from '../resources/imgs/geologo_min.png'
import ferokov from '../resources/imgs/ferologo_min.png'
import engineering from '../resources/imgs/enginelogo_min.png'
import geobohemia from '../resources/imgs/bohemlogo_min.png'
import group from '../resources/imgs/grouplogo_min.png'

const MainPage = () => {
  return (
    <>
      <div className='main-container'>
        <div className='main-item main-item-zbgroup'>
          <img
            src={group}
            alt='Web společnosti ZB Group'
            className='main-item-logo main-item-logo-2'
          />
        </div>
        <Link to='/geotech'>
          <div className='main-item main-item-geotech'>
            <img
              src={geotech}
              alt='Web společnosti ZBA GeoTech'
              className='main-item-logo main-item-logo-1'
            />
          </div>
        </Link>
        <div className='main-item main-item-ferokov'>
          <img
            src={ferokov}
            alt='Web společnosti ZBA Ferokov'
            className='main-item-logo main-item-logo-1'
          />
        </div>
        <div className='main-item main-item-engineering'>
          <img
            src={engineering}
            alt='Web společnosti ZBA engineering'
            className='main-item-logo main-item-logo-1'
          />
        </div>
        <div className='main-item main-item-geobohemia'>
          <img
            src={geobohemia}
            alt='Web společnosti ZBA GeoBohemia'
            className='main-item-logo main-item-logo-1'
          />
        </div>
        <div className='main-item main-item-footer'>
          Skupina ZBA
          <br />
          Nademlejnská 651/7
          <br />
          198 00 Praha 9 - Hloubětín
        </div>
      </div>
    </>
  )
}

export default MainPage
