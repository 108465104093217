import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IoHome } from 'react-icons/io5'
import logo from '../resources/imgs/geologo.png'

const GeoNav = ({
  scrollToHeader,
  scrollToAbout,
  scrollToReference,
  scrollToJobs,
  scrollToContact,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const navbarHeight = 8
  const minLogoScale = 0.556 // Minimum scale for the logo
  const startScalingAt = 80 // Percentage of viewport height to start scaling
  const homeIconStart = 80 // Percentage of viewport height to start changing IoHome margin
  const homeIconChangeStart = (homeIconStart / 100) * window.innerHeight

  // Calculate the scale factor based on the scroll position and the start point
  const scaleFactor = Math.max(
    minLogoScale,
    1 -
      Math.max(
        0,
        scrollPosition - (startScalingAt / 100) * window.innerHeight
      ) /
        (navbarHeight * 2)
  )

  // Calculate margin-left for IoHome based on scroll position
  const homeIconMarginLeft =
    scrollPosition >= homeIconChangeStart ? '60rem' : '70rem'

  return (
    <div className='navbar'>
      <img
        src={logo}
        alt='ZBA GeoTech'
        className='navbar-logo'
        onClick={scrollToHeader}
        style={{
          WebkitTransform: `scale(${scaleFactor})`,
          WebkitTransformOrigin: 'top left',
          WebkitTransition: 'transform 0.3s cubic-bezier(0.42, 0, 0.58, 1)',
          transform: `scale(${scaleFactor})`,
          transformOrigin: 'top left',
          transition: 'transform 0.3s cubic-bezier(0.42, 0, 0.58, 1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      />
      <Link
        to='/'
        style={{
          marginLeft: homeIconMarginLeft,
          transition: 'margin-left 0.3s ease-out', // Add transition property
        }}
      >
        <IoHome className='navbar-group-button' />
      </Link>

      <div className='navbar-menu'>
        <div className='list list__item' onClick={scrollToHeader}>
          Úvod
        </div>
        <div className='list list__item' onClick={scrollToAbout}>
          O společnosti
        </div>
        <div className='list list__item' onClick={scrollToReference}>
          Reference
        </div>
        <div className='list list__item' onClick={scrollToJobs}>
          Kariéra
        </div>
        <div className='list list__item' onClick={scrollToContact}>
          Kontakty
        </div>
      </div>
    </div>
  )
}

export default GeoNav
